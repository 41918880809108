.yes-no-buttons {
    display: flex;
    bottom: 17px;
    z-index: 102;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    &_button {
        border-radius: 30px;
        height: 262px;
        width: 261px;
        display: block;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 48px;
        line-height: 40px;
        position: relative;
        z-index: 1;
        @extend %curly-layer;
        &_yes {
            background: #3DC47E;
            margin-right: 20px;
        }
        &_no {
            background: #FF4F52;
        }
    }
}