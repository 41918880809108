.chat {
    position: absolute;
    right: 10px;
    top: 10px;
    bottom: 10px;
    background: #FFFFFF;
    border-radius: 20px;
    z-index: 5;
    height: 97%;
    width: 377px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 10px 0 10px;
        &_close {
            width: 40px;
            height: 40px;
            background: #FAEFE4;
            border-radius: 12px;
            border: 1px solid #FAEFE4;
            cursor: pointer;
        }
        &_title {
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            color: #4B5157;
            margin: 0;
        }
    }
    &-input {
        display: flex;
        padding: 0 10px 10px 10px;
        background: #F5F5F5;
        border-radius: 0px 0px 20px 20px;
        &_staple-button {
            background-color: transparent;
            padding: 1.5rem;
        }
        &_submit-button {
            padding: 1.5rem;
        }
    }
    &-messages {
        height: 100%;
        overflow-y: scroll;
        margin: 0 10px 0 10px;
    }
    &-message {
        display: flex;
        flex-direction: column;
        &_title {
            display: flex;
            justify-content: space-between;
        }
    }
}