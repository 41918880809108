.form-control,
.form-select {
    height: 48px;
    border-radius: 12px;
    font-size: 12px;
    border: 1px solid #F4F4F4;
    background-color: #F4F4F4;
    
    &:hover,
    &:focus {
        border: 1px solid $blue;
    }
    &:focus {
        box-shadow: none;
    }
}

.react-tel-input {
    &>.form-control {
        height: 48px !important;
        border-radius: 12px !important;
        font-size: 12px !important;
        border: 1px solid $gray-50 !important;
        width: 100% !important;
        &:hover,
        &:focus {
            border: 1px solid $blue !important;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
    &>.flag-dropdown {
        border-radius: 12px 0 12px 12px !important;
        &>.selected-flag {
            border-radius: 12px 0 0 12px !important;
        }
    }
}

.form-text,
.text-muted {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #7B909F !important;
}

textarea.form-control {
    min-height: calc(4em + 0.75rem + 2px);
    resize: none;
    padding: 16px;
}

input[type=date]::-webkit-calendar-picker-indicator {
    background-image: url(../../../../../views/Profile/assets/calendar.svg);
    width: 24px;
    height: 24px;
}

.was-validated .form-control:invalid, .form-control.is-invalid{
    background-image: url(../../../../../views/SuccessSignUp/assets/invalid.svg) !important;
    background-size: 21px;
    &:hover{
        border-color: #dc3545 !important;
    }
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"]{
    background-image: url(../../../../../views/SuccessSignUp/assets/invalid.svg) !important;
    background-size: 21px;
}