.footer-container {
  background-color: #FFFFFF;
  width: 100%;
  margin-top: 32px;
}

.footer {
  background-color: #FFFFFF;
  height: 362px;
  margin: 0 auto;
  padding: 47px 20px 0 20px;
  display: flex;
  flex-direction: column;
  max-width: 1210px;

  &__first-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__divider {
    height: 1px;
    background-color: #EEF1F5;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: space-between;

    &__title {
      color: #4B5157;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
    }

    &__address {
      color: #7B7B7B;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      max-width: 260px;
    }

    &__privacy {
      color: #7B7B7B;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
      text-decoration-line: none;
    }

    &__copyright {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #7B7B7B;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
    }

    &__docs {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #7B7B7B;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    gap: 14px;

    &__title {
      color: #4B5157;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
    }

    &__logo {
      width: 40px;
      height: 40px;
    }

    &__links {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    &__link {
      border: none;
      padding: 0;
      background-color: transparent;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;

    &__tel {
      color: #4B5157;
      font-size: 24px;
      font-weight: 700;
      line-height: 130%;
      text-decoration: none;
    }

    &__schedule {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 8px;

      &__online {
        margin-top: 8px;
        width: 8px;
        height: 8px;
        background-color: #27CA40;
        border-radius: 1000px;
      }

      &__container {
        display: flex;
        flex-direction: column;
      }

      &__text {
        color: #4B5157;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
      }
    }

    &__messengers {
      display: flex;
      flex-direction: row;
      gap: 4px;
      margin-top: 15px;
    }
  }

  &__faq {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__text {
      color: #4B5157;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
      text-decoration: none;
    }
  }
}

