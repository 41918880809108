:root {
    --plyr-color-main: #4E88FB;
}

.plyr--video {
    border-radius: 20px;
    height: 590px;
    overflow: hidden;
}

.plyr--video.plyr--menu-open {
    overflow: hidden !important;
}

.plyr__control--overlaid svg {
    transform: translate(-48%);
    width: 100px !important;
    height: 32px !important;
    left: 50% !important;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
    width: 100px;
    height: 100px;
    background: #4E88FB;
}