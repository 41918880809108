.success-sign-up {
    position: relative;
    &__background {
        @extend %background-image-cover;
        background-position: initial;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../views/SuccessSignUp/assets/background.jpg");
    }
    &__first-background{
        @extend %background-image-cover;
        background-position: initial;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../views/SuccessSignUp/assets/firstBackground.jpg");
    }
    &__second-background{
        @extend %background-image-cover;
        background-position: initial;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../views/SuccessSignUp/assets/secondBackground.jpg");
    }
    &__lesson-state-card {
        margin: auto 126px;
        width: 440px;
        min-height: 200px;
        overflow: unset;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: unset;
    }
    &-clicable{
        &-wrapper{
            position: relative;
            display: flex;
            align-items: baseline;
            &-text{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                text-decoration-line: underline;
                color: #FFFFFF;
                cursor: pointer;
                margin: 0 4px 0 0;
                &:hover{
                    text-decoration-line: unset;
                    color: #E9EFF3;
                }
            }
            &-icon{
                &:hover{
                    .success-sign-up-clicable-wrapper-hidden-text{
                        display: block;
                        position: absolute;
                        top: -2px;
                        max-width: 230px;
                        background: #4b5157;
                        border-radius: 8px;
                        color: white;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        padding: 8px;
                        cursor: unset;
                        z-index: 2;
                        box-shadow: 0px 2px 4px 0px #00000024;
                        &::after {
                          content: '';
                          position: absolute;
                          left: -16px;
                          top: 6px;
                          border: 10px solid transparent;
                          border-right: 10px solid #4b5157;
                        }
                    }
                }
            }
            &-hidden-text{
                display: none;
            }
        }
    }
    &-home-btn{
        border: unset;
        background: #217DFF;
        border-radius: 16px;
        width: 309px;
        height: 48px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
    }
    &-form{
        width: 309px;
        &_input{
            padding-left: 40px;
            &-wrapper{
                position: relative;
                &:hover{
                    .success-sign-up-form_input-wrapper-tooltip{
                        display: block;
                        position: absolute;
                        top: 7px;
                        max-width: 200px;
                        background: #4b5157;
                        border-radius: 8px;
                        color: white;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        padding: 8px;
                        cursor: unset;
                        z-index: 2;
                        box-shadow: 0px 2px 4px 0px #00000024;
                        &::after {
                          content: '';
                          position: absolute;
                          left: -16px;
                          top: 6px;
                          border: 10px solid transparent;
                          border-right: 10px solid #4b5157;
                        }
                    }
                }
                &-tooltip{
                    display: none;
                }
            }
            &-svg{
                position: absolute;
                top: 50%;
                left: 12px;
                transform: translateY(-50%);
            }
        }
        &_btn{
            border: unset;
            background: #217DFF;
            border-radius: 16px;
            width: 309px;
            height: 48px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;
            align-items: center;
            padding: 16px 14px 16px 20px;
            display: flex;
            justify-content: space-between;
            svg{
                stroke: white;
            }
            &:disabled{
                background: #ACCEFF;
            }
        }
    }
}