.lesson-state-card {
  @include blur_background();

  font-size: 1rem;
  max-height: 100%;
  // padding: 2em 1.96875em;
  padding: 60px;
  box-sizing: border-box;
  border-radius: 11px;
  text-align: start;
  color: white;
  max-width: 429px;
  // overflow: auto;
  width: 100%;

  &__logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
    min-height: 80px;
  }

  &__header-container {
    text-align: start;
    color: white;
    width: 100%;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 1.5em;

    font: {
      style: normal;
      weight: bold;
    }

    margin: 0;
    line-height: 1.2em;
    text-align: start;
    word-break: break-word;
  }

  &__hint {
    font-size: 0.875em;
    margin-top: 9px;
    margin-bottom: 0;
    color: #BCBFC2;
  }

  &__form {
    font-size: 0.75em;

    > *:nth-child(n + 2) {
      margin-top: 12px;
    }
  }

  &__button {
    width: 100%;
    font-weight: bold;
  }

  &__clickable-text {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
    // font-weight: bold;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    &:hover{
      text-decoration: unset;
      color: #E9EFF3;
      }
  }
}
