.popover {
  margin-top: 30px; // ?
  --bs-popover-border-radius: 14px;
  --bs-popover-inner-border-radius: 14px;

  &-inner {
    background: #ffffff;
    border-radius: 14px;
  }

  &-arrow {
    transform: translate(0px, 17px) !important;
  }

  &-menu {
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    overflow: hidden;

    &_item {
      border: none;
      background: #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: 34px;
      padding: 10px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
      color: #4b5157;
      margin: 0;

      &:hover {
        background: #f4f8fc;
      }

      svg {
        margin-right: 8px;
      }
    }
  }
}
