.notification-wrapper {
    height: 38px;
    background: #4B5157;
    border-radius: 4px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    &_text {
        color: #FFFFFF;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        margin: auto;
        padding: 12px 14px;
    }
    &-plug{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: #A5A8AB;
        margin-bottom: 20px;
    }
}