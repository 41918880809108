.free-form {
    padding: 24px 20px 24px 24px;
    background: #FCF5E5;
    border-radius: 12px;
    margin-bottom: 30px;
    display: flex;
    min-height: 290px;
    background-image: url(../../views/Profile/assets/freeFormBackground.svg);
    background-repeat: no-repeat;
    // background-position-x: right;
    // background-position-y: bottom;
    background-position: 85% 0;
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 130%;
        color: #4B5157;
    }
    &_have-more{
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 130%;
        color: #4B5157;
        margin: 0;
    }
    &_description {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6A6A6A;
    }
    &_elements {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        margin: 24px 0 24px 0;
        &-item {
            background: #F2E7CC;
            border-radius: 100px;
            padding: 6px 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #4B5157;
            margin: 0;
        }
    }
    &_status {
        padding: 16px 14px 16px 20px;
        background: #FFFFFF;
        border-radius: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #4B5157;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 222px;
        margin: 0;
        width: 100%;
        height: 48px;
    }
}

.education-form {
    &-wrapper {
        padding: 24px 20px 24px 24px;
        border-radius: 12px;
        margin-bottom: 30px;
        display: flex;
        min-height: 290px;
        &_BASE_SKILLS {
            background: #E8FAF6;
            background-image: url(../../views/Profile/assets/baseFormBackground.svg);
            background-repeat: no-repeat;
            background-position: 85% 0;
            .free-form_elements-item {
                background: #CFEFE9;
            }
        }
        &_PERSONAL {
            background: #E2FAFE;
            background-image: url(../../views/Profile/assets/individualFormBackground.svg);
            background-repeat: no-repeat;
            background-position: 85% 0;
            .free-form_elements-item {
                background: #C9F1F7;
            }
        }
        &_GROUP {
            background: #E6F5E7;
            background-image: url(../../views/Profile/assets/groupFormBackground.svg);
            background-repeat: no-repeat;
            background-position: 85% 0;
            .free-form_elements-item {
                background: #C8E5CA;
            }
        }
        &_STUDENT_PREPARATION {
            background: #FEF1F8;
            background-image: url(../../views/Profile/assets/preparationFormBackground.svg);
            background-repeat: no-repeat;
            background-position: 85% 0;
            .free-form_elements-item {
                background: #FBDCEC;
            }
        }
    }
    &-button-wrapper {
        display: flex;
    }
    &_price-btn {
        padding: 16px 14px 16px 20px;
        background: #4592FF;
        border-radius: 16px;
        border: 1px solid transparent;
        display: flex;
        justify-content: space-between;
        max-width: 222px;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        align-items: center;
        margin-right: 14px;
        height: 48px;
        margin-top: 10px;
        &-wapper {
            display: flex;
            align-items: center;
        }
    }
    &_info-btn {
        padding: 16px 14px 16px 20px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid transparent;
        max-width: 222px;
        width: 100%;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #4B5157;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        margin-top: 10px;
    }
}

.active-form {
    &_end {
        padding: 10px 16px 10px 10px;
        background: #FFFFFF;
        border-radius: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #7B7B7B;
        display: flex;
        align-items: center;
        max-width: 160px;
        margin: 0;
        width: 100%;
        height: 48px;
        svg {
            margin-right: 10px;
        }
    }
}