.control-block {
    display: flex;
    align-items: center;
    position: relative;
    .volume,
    button {
        margin-right: 8px;
        line-height: initial;
    }
    button:last-of-type {
        margin-right: 0;
    }
    &_teacher {
        margin-right: auto;
        margin-left: 20px;
    }
}

.volume {
    display: flex;
    flex-direction: column;
    position: relative;
    &__slider-wrapper {
        $padding: 0.21875em;
        font-size: 1.0625rem;
        width: 5.5em; //88px;
        height: 1.25em; //20px;
        background-clip: padding-box;
        border-width: 0.25em; //4px;
        border-style: solid;
        border-color: #30363D;
        border-radius: 100px;
        background-color: #fff;
        padding: $padding; //3.5px;
        box-sizing: border-box;
        position: absolute;
        top: -4.125em;
        right: 50%;
        transform: translate(50%, 0) rotate(-90deg);
        z-index: 10;
        .volume__slider {
            $size: calc(100% - (#{$padding} * 2));
            font-size: inherit;
            outline: 0;
            border: 0;
            border-radius: 100px;
            margin: 0;
            overflow: hidden;
            -webkit-appearance: none;
            background-color: #fff;
            position: absolute;
            width: $size;
            height: $size;
            &::-webkit-slider-thumb {
                $thumb-size: 0.313em;
                $shadow-size: $thumb-size * 7;
                $shadow-offset: calc(-1 * ($shadow-size + $thumb-size / 2));
                height: $thumb-size;
                width: $thumb-size;
                -webkit-appearance: none;
                cursor: pointer;
                box-shadow: $shadow-offset 0 0 $shadow-size #4E88FB, inset 0 0 0 $thumb-size #4E88FB;
                border-radius: 50%;
                transition: box-shadow 0.2s ease-in-out;
            }
        }
    }
}