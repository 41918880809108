.my-progress-upload-modal {
  &-trash{
    cursor: pointer;
  }
  &-item {
    padding-top: 20px;
  }
  &_download {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #4592ff;
    padding: 16px 14px 16px 20px;
    border: 2px dashed #4592ff;
    border-radius: 16px;
    background-color: white;
    height: 48px;
    margin-top: 12px;
    svg{
      fill:#4592FF;
      margin-right: 12px;
  }
  }
  &_invalid{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }
  &_save-btn {
    padding: 16px 14px 16px 20px;
    background: #4592ff;
    border-radius: 16px;
    border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    max-width: 260px;
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    align-items: center;
    height: 48px;
    margin-top: 10px;
    justify-content: center;
    
    &:disabled{
      background: #ACCEFF;
    }
  }
  &_file-name{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #4592FF;
    margin: 0;
  }
  &_file-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  &_progress-text{
    margin: 10px 0 10px 0;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #4592FF;
  }
}

.myProgressUploadModalBody {
  display: flex;
  flex-direction: column;
  height: 87%;
  margin: 0 20px 0 0;
}

.loading {
  width: 1rem;
  height: 1rem;
  &_wrapper{
    display: flex;
    align-items: center;
  }
}
