.student_list{
    &-header{
        display: flex;
        justify-content: space-between;
        &-my-progress{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 30px;
            &_wrapper{
                display: flex;
            }
            &_select{
                padding: 12px 16px;
                width: 262px;
                background: #F4F4F4;
                border-radius: 12px;
                margin-left: 20px;
            }
        }
        &_search{
            background: #F4F4F4;
            width: 262px;
            border: 1px solid transparent;
            padding: 12px 40px 12px 16px;
            border-radius: 12px;
            color: #7B909F;
            &:focus-visible {
                border: 1px solid transparent;
                outline: transparent;
            }
            &_wrapper{
                width: 262px;
                position: relative;
                svg {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translate(0, -50%);
                    cursor: pointer;
                }
            }
        }
        &_select{
            padding: 4px 8px;
            width: 262px;
            background: #F4F4F4;
            border-radius: 12px;
            margin-right: 20px;
            &_wrapper{
                display: flex;
                margin-top: 20px;
                margin-bottom: 30px;
            }
        }
        &_download{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 222px;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #4592FF;
            padding: 16px 14px 16px 20px;
            border: 2px solid #4592FF;
            border-radius: 16px;
            background-color: white;
            height: 48px;
            svg{
                fill:#4592FF;
            }
            &:hover{
                border: 2px solid #4B5157;
                color: #4B5157;
                svg{
                    fill:#4B5157;
                }
            }
        }
    }
    &-item{
        background: #FFFFFF;
        border: 1px solid #EEF1F5;
        border-radius: 12px;
        padding: 12px 20px 12px 12px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        &_user{
            display: flex;
            align-items: center;
            margin-right: 30px;
            width: 100%;
            &_avatar{ 
                border-radius: 100%;
                height: 48px;
                width: 48px;
                margin-right: 20px;
            }
            &-name{
                margin: 0;
                max-width: 200px;
                width: 100%;
                margin-right: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #4B5157;
                word-break: break-word;
            }
        }
        &_text{
            margin: 0;
            max-width: 200px;
            width: 100%;
            margin-right: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #4B5157;
        }
        &_age{
            margin: 0;
            max-width: 100px;
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #4B5157;
        }
    }

}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}
.rmsc {
    --rmsc-main: #F4F4F4 !important;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #F4F4F4 !important;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px;
    --rmsc-radius: 4px;
    --rmsc-h: 38px;
}

.rmsc .dropdown-heading {
    background-color: #F4F4F4 !important;
}