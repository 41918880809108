.preloader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.preloader {
    width: 60px;
    height: 60px;
    animation: preloader-rotate 2s infinite linear;
}

.preloaderSmall {
    width: 10%;
    height: 10%;
    animation: preloader-rotate 2s infinite linear;
}

.preloaderButton {
    width: 15%;
    height: 50%;
    animation: preloader-rotate 2s infinite linear;
}

@keyframes preloader-rotate {
    100% {
        transform: rotate(360deg);
    }
}

// .loader{
//     width: 100px;
//     height: 100px;
//     border-radius: 100%;
//     position: relative;
//     margin: 0 auto;
//   }
//   #loader-1:before, #loader-1:after{
//     content: "";
//     position: absolute;
//     top: -10px;
//     left: -10px;
//     width: 100%;
//     height: 100%;
//     border-radius: 100%;
//     border: 10px solid transparent;
//     border-top-color: #217DFF;
//   }
  
//   #loader-1:before{
//     z-index: 100;
//     animation: spin 1s infinite;
//   }
  
//   #loader-1:after{
//     border: 10px solid #ccc;
//   }
  
//   @keyframes spin{
//     0%{
//       -webkit-transform: rotate(0deg);
//       -ms-transform: rotate(0deg);
//       -o-transform: rotate(0deg);
//       transform: rotate(0deg);
//     }
  
//     100%{
//       -webkit-transform: rotate(360deg);
//       -ms-transform: rotate(360deg);
//       -o-transform: rotate(360deg);
//       transform: rotate(360deg);
//     }
//   }