.main-container {
  max-width: $maxContentWidth;
  width: 100%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px 0;
  min-height: calc(100% - #{map_get($headerHeightList, normal)} - #{$footerHeight});
};

.main-header-container {
  max-width: $maxContentWidth;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 20px 0;
};

.content-container {
  //min-height: 670px;
  height: calc(100% - #{map_get($headerHeightList, normal)} - #{$footerHeight});

  @media screen and (max-height: 813px) {
    //min-height: 670px;
    height: 100% - #{map_get($headerHeightList, small)} - #{$footerHeight};
  }
}

.content-container_without-footer {
  $marginBottom: 30px;
  padding-top: 10px;
  height: 670px;
  //height: calc(100% - #{map_get($headerHeightList, normal)} - #{$marginBottom});
  @media screen and (max-height: 813px) {
    height: 670px;
    //height: calc(100% - #{map_get($headerHeightList, small)} - #{$marginBottom});
  }
}



.card-container {
  @extend %background-image-cover;
  max-width: 1170px;
  min-height: 670px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  overflow: hidden;
  /* помогло решить проблему с бордерами на трансляции */
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
};
