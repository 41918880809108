.main-preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 9999;
  background-color: white;
  background-image: url("../assets/background.svg");

  &__content {
    margin: auto;
  }

  &__logo-icon {
    width: 165px;
    height: 103.5px;
    animation: test 1.5s linear infinite;
  }
}

@keyframes test {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.07);
  }
  to {
    transform: scale(1);
  }
}