.nav-link {
    display: flex;
}

.dropdown-toggle::after{
    // margin-top: 7px;
    margin-left: 8px;
    content: url(../../../../../assets/img/dropdownIcon.svg);
    border: 0;
}

.dropdown-menu{
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
    top: 55px;
    border: 1px solid #E9EFF3;
    border-radius: 14px;
    min-width: 220px;
}

.dropdown-item{
    margin: 0;
}