.student-options {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(8px);
    border-radius: 14px;
    width: 190px;
    height: 264px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    z-index: 4;
    right: 20px;
    &_buttons {
        display: flex;
        align-items: center;
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        margin-bottom: 22px;
        &_text {
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 14px;
            color: #4B5157;
            margin: 0 0 0 10px;
        }
    }
}