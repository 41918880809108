.change-password {
    &-wrapper {
        position: relative;
        z-index: 1;
    }
    &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #4B5157;
    }
    &-icon {
        padding: 0 !important;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -55%);
        z-index: 2 !important;
        background: transparent;
        border-radius: unset;
        border: 1px solid transparent;
        svg {
            fill: #7B909F;
        }
        &-invalid {
            transform: translate(0, -100%);
            svg {
                fill: #dc3545;
            }
        }
    }
    &-submit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 262px;
        width: 100%;
        svg {
            stroke: white;
        }
    }
    &-input {
        background: #EEF2F5;
        position: relative;
        padding-right: 40px;
        padding-left: 40px;
    }
    &-info_icon{
        margin-right: 8px;
    }
}

.eye_btn {
    position: absolute !important;
    width: 35px;
    height: 45px !important;
    border-radius: 12px !important;
    top: 1px;
    right: 0;
    height: 36px;
    transform: translate(-7%, 0%);
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 !important;
    &:focus {
        box-shadow: unset;
        outline: 0;
    }
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-radius: 12px;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding-right: 40px;
    padding-left: 40px;
    z-index: 1;
    background: #EEF2F5;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    .change-password-icon {
        transform: translate(0, -100%) !important;
    }
}

.input-group-wrapper {
    display: flex;
    flex-direction: column;
}

.password-input {
    width: 100% !important;
    padding-right: 45px !important;
    padding-left: 0.75rem !important;
}

.recovery-btn {
    height: 51px;
    padding: 0 !important;
    width: 200px;
    &_submit {
        width: 131px;
        height: 51px;
        padding: 0 !important;
    }
}