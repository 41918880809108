.profile-tabs {
  background: #ffffff;
  padding: 6px;
  box-shadow: 0px 8px 12px rgba(214, 211, 206, 0.16);
  border-radius: 1000px;
  margin-bottom: 20px;
  max-width: 462px;
  height: 60px;
  display: flex;
  &_mentor {
    max-width: 322px;
  }
  &_admin{
    max-width: 341px;
  }
  &_button {
    padding: 12px;
    border: 1px solid transparent;
    background: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #6a6a6a;
    text-decoration: none;
    svg {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
    &:hover {
      padding: 12px;
      background: #f7f9fa;
      border-radius: 1000px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #6a6a6a !important;
    }
  }
  &_active {
    padding: 12px;
    background: #ffdb75;
    border-radius: 1000px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #6a6a6a;
    svg {
      color: #6a6a6a;
      width: 24px;
      height: 24px;
    }
    &:hover {
      background: #ffdb75;
    }
  }
}
