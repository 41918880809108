.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: rgba(48, 54, 61, 0.9);
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
  text-align: center;
  overflow-y: auto;
}

.modalOverlay::after {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
  content: '';
}

.themeModalWindow {
  display: inline-block;
  vertical-align: middle;
  text-align: start;
  z-index: 2;
  position: relative;
  // overflow: hidden;
}

.modalWindow {
  display: inline-block;
  vertical-align: middle;
  text-align: start;
  border-radius: 20px;
  background: #ffffff;
  z-index: 2;
  position: relative;
  overflow: hidden;
  &_chat-img {
    background: none;
  }
  &_standart {
    width: 570px;
    height: 566px;
    padding: 16px 0 0 32px;
  }
  &_small {
    width: 570px;
    height: 312px;
    padding: 16px 0 0 32px;
    margin: 294px auto;
  }
  &_very-small {
    width: 570px;
    min-height: 12.5rem;
    padding: 16px 16px 32px 32px;
    margin: 294px auto;
    box-sizing: border-box;
    overflow: unset;
    .closeBtn {
      align-self: flex-start;
    }
  }
  &_timesheet {
    width: 570px;
    min-height: 12.5rem;
    padding: 35px 30px 30px;
    margin: 294px auto;
    box-sizing: border-box;
    overflow: unset;
    .closeBtn {
      align-self: flex-start;
      margin: 0;
    }
  }
  &_reaction {
    width: 570px;
    height: 167px;
    padding: 16px 16px 32px 32px;
    margin: 294px auto;
    box-sizing: border-box;
  }
  &_attention {
    width: 570px;
    height: 227px;
    padding: 12px 16px 0 32px;
    box-sizing: border-box;
    margin: 294px auto;
  }
  &_create-folder {
    padding: 35px 30px 30px;
    width: 322px;
    min-height: 263px;
    overflow: unset;
    .closeBtn {
      margin: 0;
      transform: translate(16px, -24px);
    }
    .modalTitle {
      margin-bottom: 32px;
    }
  }
  &_request {
    width: 570px;
    height: unset;
    padding: 32px;
    margin: 294px auto;
    box-sizing: border-box;
    .closeBtn {
      margin: 0;
      transform: translate(16px, -24px);
    }
    .modalTitle {
      color: #4b5157;
      max-width: 274px;
    }
  }
  &_cropper {
    min-width: 612px;
    max-width: 970px;
    min-height: 270px;
    max-height: 825px;
    padding: 32px;
    box-sizing: border-box;
    // .modalHeader {
    //   margin-bottom: 32px;
    // }
  }
  &_leave-page {
    width: 570px;
    height: unset;
    padding: 32px;
    margin: 294px auto;
    box-sizing: border-box;
    .closeBtn {
      margin: 0;
      transform: translate(16px, -24px);
    }
    .modalTitle {
      color: #4b5157;
      max-width: 80%;
    }
    .delete-modal__confirm-button {
      padding: 0;
    }
    .delete-modal__cancel-button {
      padding: 0;
    }
  }
  &_make-order {
    width: 570px;
    height: unset;
    padding: 32px;
    margin: 294px auto;
    box-sizing: border-box;
    .modalTitle {
      color: #4b5157;
      max-width: 80%;
    }
    .closeBtn {
      margin: 0;
      align-self: baseline;
    }
  }
  &_reload-app {
    width: 570px;
    height: 12.5rem;
    padding: 16px 32px;
    margin: 294px auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .modalHeader {
      flex: 1;
    }
  }
  &_emotion-button {
    @extend %curly-layer;
    padding: 125px;
    border-radius: 1000px;
    &_toilet {
      background: #ffd150;
      box-shadow: 6px 6px 120px rgba(251, 228, 164, 0.81);
    }
    &_handUp {
      box-shadow: 6px 6px 120px #98e0cf;
      background: #81e3cf;
    }
    &_firstThemeButton {
      filter: drop-shadow(6px 6px 120px #97edfd);
      background: #97edfd;
    }
    &_secondThemeButton {
      filter: drop-shadow(6px 6px 120px #fa96c8);
      background: #fa96c8;
    }
    &_image {
      width: 250px;
      height: 250px;
      border: 3px solid white;
      border-radius: 10px;
    }
  }
  &_yes-no-button {
    @extend %curly-layer;
    border-radius: 30px;
    padding: 125px 110px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 40px;
    color: #ffffff;
    &_yes {
      background: #3dc47e;
      box-shadow: 6px 6px 120px rgba(83, 215, 138, 0.66);
    }
    &_no {
      background: #ff4f52;
      box-shadow: 6px 6px 120px #ff4f52;
    }
  }
  &_emoji-button {
    background: unset;
    filter: drop-shadow(0px 0px 66px #ffd150);
    height: 192px;
    width: 192px;
    &-image {
      width: 100%;
      height: 100%;
    }
    &_red {
      filter: drop-shadow(0px 0px 66px rgba(255, 0, 0, 0.4));
    }
  }
  &_education-forms {
    width: 610px;
    height: 780px;
    padding: 16px 0 20px 32px;
  }
  &_my-progress-upload {
    width: 322px;
    min-height: 380px;
    padding: 16px 0 20px 32px;
    overflow: unset;
  }
  &_join-lesson {
    width: 570px;
    height: 285px;
    padding: 32px;
    .closeBtn {
      margin: 0;
    }
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  height: 87%;
  overflow-y: scroll;
  margin: 0 10px 0 0;
}

.modalBody::-webkit-scrollbar-track {
  border-radius: 20px;
}

.modalBody::-webkit-scrollbar {
  width: 12px;
}

.modalBody::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: inherit;
}

.modalBody::-webkit-scrollbar-thumb {
  background-color: #acceff;
  margin: 0 10px 0 0;
}

.modalContainer {
  height: 90%;
  overflow-y: scroll;
  margin: 0 10px 0 0;
}

.modalContainer::-webkit-scrollbar-track {
  border-radius: 20px;
}

.modalContainer::-webkit-scrollbar {
  width: 12px;
}

.modalContainer::-webkit-scrollbar-thumb {
  background-color: #faefe4;
  margin: 0 10px 0 0;
  border-radius: 20px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalTitle {
  margin: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
}

.closeBtn {
  width: 40px;
  height: 40px;
  background: #f4f8fc;
  border-radius: 12px;
  border: 1px solid #f4f8fc;
  margin: 0 16px 0 0;
  cursor: pointer;
  display: flex;
  padding: 0;
  img {
    margin: auto;
  }
}

.faqTextHeader {
  display: flex;
  justify-content: space-between;
}

.faqTextWrapper {
  width: 506px;
  background: #e4ebf0;
  border-radius: 10px;
  padding: 16px 16px 0 16px;
  margin-bottom: 16px;
  cursor: pointer;
}

.faqTextWrapper:first-child {
  margin: 24px 0 16px 0;
}

.faqNameText {
  width: 448px;
  margin: 0;
  padding: 0 0 16px 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #30363d;
}

.faqArrow {
  align-self: baseline;
}

.faqSlice {
  align-self: baseline;
  display: none;
}

.faqDescriptionText {
  padding: 16px 0 16px 0;
  border-top: 1px solid #cfdee9;
  margin: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-line;
  text-align: justify;
}

.description {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4b5157;
}

.active {
  display: block;
}

.disable {
  display: none;
}

.modalWrapper {
  display: flex;
  margin-top: 24px;
  align-items: center;
  &__change-media-state-wrapper {
    svg.confirm path {
      stroke: #ffffff;
    }
    svg.cancel path {
      stroke: #4e88fb;
    }
  }
  &_input {
    padding: 12px;
    margin-right: 10px;
    width: 61%;
    border: 1px solid #4e88fb;
  }
  &_submit-button {
    height: 48px;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  &_attention {
    display: flex;
    flex-wrap: wrap;
    &-button {
      background-color: white;
      border: 1px solid #4e88fb;
      box-sizing: border-box;
      border-radius: 16px;
      color: #4e88fb;
      width: 247px;
      margin: 10px 10px 0 0;
    }
  }
  &_reaction {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    &-button {
      stroke: transparent;
      margin-right: 10px;
      background: #faefe4;
    }
  }
}

.share {
  position: absolute;
  background: #ff4f52;
  border-radius: 38px;
  z-index: 4;
  padding: 10px 32px;
  color: #ffffff;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  &_ellipse {
    margin-right: 12px;
  }
}

.teaher-camera-request {
  &_accept {
    margin-right: 20px;
    background: #4e88fb;
    width: 247px;
    height: 48px;
    padding: 17px 20px;
    border-radius: 16px;
  }
  &_deniede {
    background-color: white;
    border: 1px solid #4e88fb;
    box-sizing: border-box;
    border-radius: 16px;
    color: #4e88fb;
    width: 247px;
    height: 48px;
    padding: 17px 20px;
    border-radius: 16px;
  }
}

.cropper-modal {
  &-wrapper {
    display: flex;
    flex-direction: column;
  }
  &_title {
    max-width: 564px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #656b70;
    margin-bottom: 24px;
  }
  &-add-btn {
    max-width: 200px;
    color: #4e88fb !important;
    margin-bottom: 12px;
    &:hover {
      color: white !important;
    }
  }
}

.education-info {
  &-wrapper {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #7b909f;
    margin-bottom: 16px;
    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 130%;
      color: #4b5157;
      margin: 0;
    }
  }
  &-img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
  }
  &-items {
    display: flex;
    padding: 12px 10px 14px;
    border-radius: 16px;
    margin-bottom: 16px;
    width: 98%;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
    // svg {
    //     width: 54px;
    //     height: 32px;
    //     margin-right: 16px;
    // }
    &_svg {
      margin-right: 16px;
    }
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: #4b5157;
      margin-bottom: 4px;
    }
    &-description {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b909f;
    }
  }
  &_curator {
    background: rgba(252, 238, 244, 0.46);
  }
  &_individ {
    background: rgba(252, 245, 229, 0.67);
  }
  &_search {
    background: rgba(221, 245, 248, 0.38);
  }
  &_group {
    background: rgba(69, 146, 255, 0.07);
  }
  &_timesheet {
    background: #ecf7ed;
  }
  &_program {
    background: #f0fcf9;
  }
  &_success {
    background: rgba(247, 241, 253, 0.58);
  }
  &_support {
    background: rgba(243, 216, 228, 0.24);
  }
}

.materials-modal {
  &_wrapper {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
  &_plus {
    border: 1px solid transparent;
    background: #eef2f5;
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  &_minus {
    border: 1px solid transparent;
    background: #eef2f5;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    margin-right: 12px;
  }
  &_count {
    padding: 12px 10px 12px 16px;
    background: #eef2f5;
    border-radius: 12px;
    width: 120px;
    height: 48px;
    box-sizing: border-box;
    margin-right: 12px;
    margin-bottom: 0;
    text-align: center;
  }
  &_buy {
    background: #4592ff;
    border-radius: 16px;
    width: 223px;
    padding: 16px 14px 16px 20px;
    border: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    svg {
      stroke: white;
    }
  }
  &_price {
    background: #dee4e9;
    border-radius: 12px;
    padding: 10px 16px 10px 10px;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4b5157;
    height: 48px;
    width: 158px;
    align-items: center;
    display: flex;
  }
  &_make-order {
    margin-top: 24px;
    background: #4592ff;
    border-radius: 16px;
    width: 223px;
    padding: 16px 14px 16px 20px;
    border: unset;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    &-text {
      margin: 0;
    }
  }
  &-second-step {
    &-text {
      margin-bottom: 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      color: #7b7b7b;
    }
    &-button {
      background: #4592ff;
      border-radius: 16px;
      width: 222px;
      padding: 16px 14px 16px 20px;
      border: unset;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      margin-right: 10px;
      // display: flex;
      text-align: center;
    }
  }
}

.save-image-modal {
  &-button_container {
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  }
  &-button_wrapper {
    background-color: #4e88fb;
    display: flex;
    border-radius: 6.25em;
  }
}

.picture-modal-body {
  height: 75vh;
  width: 75vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.my-progress-modal {
  &_lable {
    margin: 0 0 0 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px !important;
    line-height: 14px;
    color: #4592ff !important;
  }
}

.join-lesson {
  &-wrapper {
    display: flex;
    flex-direction: column;
  }
  &-input {
    padding: 12px 10px 12px 16px;
    width: 262px;
    height: 48px;
    background-color: #f4f4f4;
    border-radius: 12px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    &::placeholder {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #7b7b7b;
      display: flex;
      align-items: center;
    }
    &:focus-visible {
      outline: none;
    }
  }
}

.download-timesheet {
  padding: 16px 14px 16px 20px;
  background: #4592ff;
  border-radius: 16px;
  border: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  max-width: 222px;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  align-items: center;
  height: 48px;
  margin-top: 20px;
  justify-content: center;

  &:disabled {
    background: #acceff;
  }
}
