.finish-lesson-modal {

  &__confirm-button {
    background-color: white;
    border: 1px solid #4E88FB;
    box-sizing: border-box;
    border-radius: 16px;
    color: #4E88FB;
    width: 247px;
    display: block;
    padding: 14px 20px;
    height: 48px;
  }

  &__cancel-button {
    margin-right: 20px;
    background: #4E88FB;
    width: 247px;
    display: block;
    padding: 14px 20px;
    height: 48px;
    border-radius: 16px;
    box-sizing: border-box;
  }
}