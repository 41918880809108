.ring-emoji-chat-block {
    display: flex;
    align-items: center;
    width: 261px;
    &_only-chat {
        width: unset;
    }
    button {
        margin-right: 8px;
        &:last-of-type {
            margin-right: 0;
            position: relative;
            .unread-counter {
                display: block;
                transition: 0.3s ease-in;
                transform: scale(0);
                font-size: 12px;
                line-height: 18px;
                color: white;
                position: absolute;
                top: -6px;
                right: -6px;
                width: 24px;
                height: 24px;
                background: #FF4F52;
                text-align: center;
                border-radius: 50%;
                padding: 3px 0 0 0;
                &.active {
                    transform-origin: center center;
                    transform: scale(1);
                }
            }
        }
    }
    &__button-wrapper {
        width: 100%;
    }
    &__ring-button {
        width: 100%;
        &_preloader {
            background-color: white;
            padding: 16px 0;
            border-radius: 6.25em;
            margin-right: 8px;
        }
    }
    &__emoji-list {
        display: flex;
        align-items: center;
        padding: 7px 5.5px;
        background-color: $white;
        border-radius: 100px;
        .ring-emoji-chat-block__emoji-button {
            margin: 0 6px 0 0;
            &:last-of-type {
                margin: 0;
            }
        }
    }
    &__emoji-button {
        background-color: $beige;
        box-sizing: border-box;
        padding: 0.3125em;
        margin-right: 6px;
        .button__icon {
            width: 24px;
            height: 24px;
        }
    }
    &__close-button{
        width: 24px;
        height: 24px;
    }
}