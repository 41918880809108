.mobile-plug {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding-top: 32px;
    box-sizing: border-box;
    &-text {
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        line-height: 30px;
        text-align: center;
        color: #8250CD;
        width: 316px;
    }
}

.mobile-orientation {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-img {
            width: 48px;
            height: 48px;
        }
        &-text {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            text-align: center;
            color: #30363D;
        }
    }
}