.student-reaction {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 75px;
    &-tile {
        display: flex;
        background: #FDF8EF;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
        border-radius: 61px 61px 61px 4px;
        padding: 12px 16px 12px 12px;
        margin-top: 10px;
        &_text {
            margin: 0 0 0 10px;
        }
    }
    &_image {
        width: 24px;
        height: 24px;
    }
}