.header {
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 8px 20px rgba(43, 41, 38, 0.03);
  position: sticky;
  top: 0;
  z-index: 50;
  margin-bottom: 34px;
  &_link {
    cursor: pointer;
    margin-right: 40px;
    text-decoration: none;
    color: #4b5157;
    white-space: nowrap;
    padding: 10px;
    border: 2px solid transparent;
    &:visited {
      color: #4b5157;
    }
    &:hover {
      color: #7b7b7b;
    }
  }
  &_is-active {
    // text-decoration: underline !important;
    border: 2px solid #ffdb75;
    border-radius: 100px;
    padding: 10px;
  }
  &__content {
    margin: 0 auto;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__nav {
    flex: 1;
    display: flex;
    margin-left: 46px;
  }
  // &__nav>a {
  //     color: #4B5157;
  //     text-decoration: none;
  // }
  // &__nav>a:hover {
  //     color: #4B5157;
  //     text-decoration: dotted;
  // }
  &__logo-container {
    padding: 15px 10px 15px 0;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    // cursor: pointer;
  }
  &__logo {
    max-height: 100%;
    // width: 100%;
    &-text{
      margin: 0 0 0 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #7B7B7B;
    }
  }
  &__navigation {
    display: flex;
    justify-content: flex-end;
    &_menu-logout-icon {
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
    &_menu-icon {
      fill: #7b909f;
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
    // &__nav>a {
    //     color: #4B5157;
    //     text-decoration: none;
    // }
    // &__nav>a:hover {
    //     color: #4B5157;
    //     text-decoration: dotted;
    // }
    &__logo-container {
      padding: 15px 10px 15px 0;
      height: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      // cursor: pointer;
    }
    &_user-wrapper {
      display: flex;
      align-items: center;
    }
    &_user-info {
      // padding-right: 31px;
      // margin-right: 38px;
      // border-right: 1px solid #BCBFC2;
      // align-items: flex-end;
      display: flex;
      flex-direction: column;
    }
    &_user-icon {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border-radius: 100px;
      object-fit: cover;
    }
    &_user-name {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #4b5157;
    }
    &_user-role {
      margin: 6px 0 0 0;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #a5a8ab;
    }
  }
  @media screen and (max-height: 940px) {
    //height: map_get($headerHeightList, small);
    height: calc(160px / 2);
  }
}
