:root {
    --toastify-color-dark: #4B5157;
}

.Toastify__toast-body {
    display: flex;
    justify-content: center;
    text-align: center;
}

.Toastify__toast-icon {
    display: none;
}

.Toastify__close-button {
    display: none;
}

.Toastify__toast-theme-dark {
    background-color: #4B5157;
}