.input {
  display: flex;
  align-items: center;
  padding: 1.32em 1.25em;
  border: 1px solid transparent;
  border-radius: 1em;
  box-sizing: border-box;
  background-clip: padding-box;
  color: $gray;
  background-color: $white;
  outline: none;

  input {
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    border: none;
    width: 100%;
    color: inherit;

    &:focus, &:hover, &:active {
      outline: unset;
    }
  }

  &:focus-within, &:hover, &:active {
    border-color: $blue;
  }

  &_icon {
    display: flex;
    cursor: pointer;
    margin-left: 0.83em;

    svg {
      width: 1.67em;
      height: 1.67em;
    }
  }

  &_error {
    box-shadow: 0 0 0.933em $red !important;
    border-color: $red !important;
    color: $red !important;

    &:focus-within, &:hover, &:active {
      border-color: $red !important;
    }
  }
}

.validation_feedback {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.react-select {
  color: var(--bs-body-color) !important;
}
