%discard-margin-and-padding {
    margin: 0;
    padding: 0;
}

%background-image-cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

%reset-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

%curly-layer {
    &:before {
        background-image: url("../assets/Vector.svg");
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.4;
        overflow: hidden;
    }
}