.lesson-break {
    background-image: url("../../views/LessonBreak/assets/background.png");
    &__lesson-state-card {
        margin: auto 126px;
        width: 355px;
        max-height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}