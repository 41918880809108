.my-pruchase {
    &_title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
        color: #4B5157;
        &_wrapper{
            display: flex;
        }
    }
    &_over-form {
        background: #217DFF;
        border-radius: 16px;
        padding: 17px 39px 17px 45px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        border: 1px solid transparent;
        margin-bottom: 40px;
        &_buttons {
            &_click{
                cursor: pointer;
            }
            border: 1px solid transparent;
            background: #F4F8FC;
            border-radius: 12px;
            padding: 24px 20px;
            max-width: 355px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            color: #4B5157;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-svg {
                width: 24px;
                height: 24px;
            }
            &-content {
                display: flex;
                align-items: center;
                // justify-content: space-around;
                max-width: 250px;
                width: 100%;
                text-align: start;
                svg{
                    margin-right: 20px;
                }
                //     height: 80px;
                //     width: 95px;
                // }
            }
            &-wrapper {
                display: flex;
                gap: 20px;
                flex-wrap: wrap;
                margin-bottom: 20px;
            }
            &-text {
                width: 200px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 130%;
                color: #4B5157;
            }
            &-count {
                background: #DBE4ED;
                border-radius: 12px;
                padding: 4px 12px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #6A6A6A;
                width: 45px;
                text-align: center;
            }
            &-img {
                width: 95px;
                height: 80px;
                margin-right: 20px;
            }
            &-info {
                margin: 0;
                max-width: 135px;
            }
        }
    }
    &_plug{
        padding-top: 80px;
    }
    &_payment{
        &_wrapper{
            background: #F4F8FC;
            border: 1px solid #FFDB75;
            border-radius: 12px;
            padding: 20px;
            min-height: 100px;
            display: flex;
            text-align: start;
            align-items: center;
            margin-bottom: 20px;
        }
        &_title{
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 130%;
            color: #4B5157;
            margin: 0 0 8px 12px;
        }
        &_text{
            margin: 0 0 0 12px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #575a5e;
        }
    }
}

.form-shop {
    &_back {
        padding: 10px 14px 10px 10px;
        background: #F4F8FC;
        border-radius: 1000px;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #7B909F;
        border: 1px solid transparent;
        margin-bottom: 20px;
        svg {
            transform: rotate(180deg);
        }
    }
}

.delete-trash{
    margin-right: 20px;
}