.switcher {
    &_lable {
        position: relative;
        display: inline-block;
        width: 34px;
        height: 20px;
    }
    &_checkbox {
        display: none;
    }
    &_slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 35px;
    }
    &_slider::before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 35px;
    }
    &_checkbox:checked+&_slider {
        background-color: #2196F3;
    }
    &_checkbox:focus+&_slider {
        box-shadow: 0 0 1px #2196F3;
    }
    &_checkbox:checked+&_slider:before {
        -webkit-transform: translateX(15px);
        -ms-transform: translateX(15px);
        transform: translateX(15px);
    }
}