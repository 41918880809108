.teacher-control-block {
    display: flex;
    flex-direction: column;
    background-color: #30363D;
    border-radius: 16px;
    padding: 12px;
    z-index: 4;
    margin-top: 20px;
    &>* {
        margin-bottom: 12px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-tile {
        background: #FFFFFF;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 113px;
        height: 84px;
        padding: 12px;
        box-sizing: border-box;
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &_title {
            margin: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 14px;
            color: #4B5157;
        }
        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &-button {
            display: block;
            width: 91px;
            height: 28px;
            border: 1px solid #4E88FB;
            box-sizing: border-box;
            border-radius: 100px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #4E88FB;
            background-color: white;
            padding: 0;
            &-preloader {
                display: flex;
            }
            &:disabled {
                cursor: default;
                opacity: .7;
            }
        }
    }
    &-tile:first-child {
        margin-right: 10px;
    }
    &-line {
        display: flex;
        margin-bottom: 10px;
    }
    &-line:last-child {
        margin: 0;
    }
    &-theme {
        display: flex;
        padding: 13px 12px;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 16px;
        height: 56px;
        box-sizing: border-box;
        &_text {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 14px;
            margin: 0;
            text-transform: uppercase;
            color: #30363D;
        }
    }
    &__button {
        width: 100%;
        background: #FFFFFF;
        border-radius: 100px;
        padding: 14px 18px;
        justify-content: center;
        &-preloader {
            border: 1px solid white;
        }
        &_ring {
            margin-right: 10px;
        }
        &_emoji {
            background: #FFFFFF;
        }
    }
}