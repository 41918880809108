.materials-shop {
    &-search {
        background: #f4f4f4;
        width: 262px;
        border: 1px solid transparent;
        padding: 12px 40px 12px 16px;
        border-radius: 12px;
        color: #7B909F;
        &:focus-visible {
            border: 1px solid transparent;
            outline: transparent;
        }
        &-wrapper {
            width: 262px;
            position: relative;
            svg {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
            }
        }
    }
    &-select {
        padding: 3px 6px;
        background: #F4F4F4;
        width: 262px;
        margin-left: 20px;
        border: 1px solid transparent;
        border-radius: 12px;
        color: #7B909F;
    }
    &-wrapper {
        display: flex;
        gap: 20px 30px;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    &-item {
        background-repeat: no-repeat;
        max-width: 538px;
        width: 100%;
        min-height: 502px;
        background: #EEF1F5;
        border-radius: 20px;
        display: flex;
        position: relative;
        // flex-direction: column;
        // justify-content: space-between;
        &-title{
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            color: #4B5157;
            margin-bottom: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // transition: height 2s ease-in-out;
        }
        &-description{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #7B909F;
            margin-bottom: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // transition: height 2s ease-in-out;
        }
        &-info{
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 0;
            height: 195px;
            width: 100%;
            padding: 20px;
            background-color: #F4F8FC;
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            // transition: height 0.8s ease-in-out;
        }
        &-footer{
            display: flex;
            align-items: center;
        }
        &-count{
            padding: 12px 16px 12px 10px;
            background: #FFFFFF;
            border-radius: 12px;
            margin: 0 0 0 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: #7B7B7B;
        }
        &-img{
            width: 538px;
            height: 307px;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
        }
        &:hover{
            &::before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 20px;
            }
            .materials-shop-item-info{
                opacity: unset;
                height: 268px;
                border-radius: 20px;
            }
            .materials-shop-item-description{
                max-height: 126px;
                overflow: unset;
                white-space: unset;
                text-overflow: unset;
            }
            .materials-shop-item-title{
                overflow: unset;
                white-space: unset;
                text-overflow: unset;
            }
            .materials-shop-item-footer{
                align-items: end;
            height: 100%;
            }
        }
    }
    &-button {
        border: 1px solid transparent;
        background: #DEE4E9;
        border-radius: 8px;
        padding: 6px 12px 6px 6px;
        width: 115px;
        display: flex;
        &_img {
            margin-right: 12px;
        }
    }
}

.rmsc {
    --rmsc-main: #F4F4F4 !important;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #F4F4F4!important;
    --rmsc-gray: #aaa;
    --rmsc-bg: #fff;
    --rmsc-p: 10px;
    --rmsc-radius: 4px;
    --rmsc-h: 38px;
}

.rmsc .dropdown-heading {
    background-color: #F4F4F4 !important;
}