$red: #FF4F52;
$pink: #FA96C8;
$yellow: #FFD150;
$green: #3DC47E;
$blue: #4E88FB;
$turquoise: #81E3CF;
$lightBlue: #97EDFD;
$purple: #8250CD;
$gray: #4B5157;
$lightGray: #C4C4C4;
$beige: #FAEFE4;
$wheat: #FDF8EF;
$white: #ffffff;
$gray-70: #656B70;
$gray-50: #939699;