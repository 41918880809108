.attention-modal {

  &__wrapper {
    flex-direction: column;
  }

  &__button-block {
    display: flex;
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__button {
    display: flex;
    height: 48px;
    padding: 12px 20px;
    margin-right: 20px;
    background-color: white;
    border: 1px solid #4E88FB;
    box-sizing: border-box;
    border-radius: 16px;
    color: #4E88FB;
    width: 247px;

    &:last-of-type {
      margin-right: 0;
    }
  }

}