.subject {
  &-header{
    &_search{
      background: #F4F4F4;
      width: 262px;
      border: 1px solid transparent;
      padding: 12px 40px 12px 16px;
      border-radius: 12px;
      color: #7B909F;
      &:focus-visible {
          border: 1px solid transparent;
          outline: transparent;
      }
      &_wrapper{
        width: 262px;
        position: relative;
        margin-bottom: 20px;
        svg {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translate(0, -50%);
          cursor: pointer;
      }
      }
    }
  }
  &-title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4b5157;
    margin-bottom: 20px;
  }
  &-card {
    margin-bottom: 20px;
  }
  &-item {
    padding: 20px 32px 32px;
    width: 262px;
    min-height: 222px;
    background: #f4f8fc;
    border-radius: 14px;
    cursor: pointer;
    position: relative;
    &:hover{
      background: #EEF1F5;
    }
    &_text {
      display: none;
      max-width: 250px;
    }
    &_name {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 130%;
      color: #4b5157;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_count {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b909f;
      margin-bottom: 0;
    }
    svg {
      width: 160px;
      height: 135px;
      margin-bottom: 8px;
    }
    &_img {
      width: 160px;
      height: 135px;
      margin-bottom: 30px;
    }
    &-help {
      position: absolute;
      top: 17px;
      right: 17px;
      &:hover {
        .subject-item_text {
          display: block;
          position: absolute;
          top: -5px;
          background: #4b5157;
          border-radius: 8px;
          color: white;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          padding: 8px;
          cursor: unset;
          z-index: 2;
          box-shadow: 0px 2px 4px 0px #00000024;
          &::after {
            content: '';
            position: absolute;
            left: -16px;
            top: 6px;
            border: 10px solid transparent;
            border-right: 10px solid #4b5157;
          }
        }
      }
    }
  }
  &-wrapper {
    display: flex;
    gap: 19px;
    flex-wrap: wrap;
  }
  &_material {
    &-idea {
      &_wrapper{
        background: #f4effb;
        border-radius: 12px;
        padding: 20px;
        min-height: 100px;
        display: flex;
        text-align: start;
        border: 1px solid transparent;
        align-items: center;
        margin-bottom: 30px;
        svg {
          margin-left: auto;
        }
      }
     &_text{
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #a5a8ab;
     }
    }
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: #7b7b7b;
      margin: 0 0 6px 0;
    }
    &-text {
      margin: 30px 0 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #a5a8ab;

    }
    &_icon {
      margin-right: 20px;
    }
    &-item {
      border: 1px solid #eef1f5;
      border-radius: 12px;
      padding: 12px 20px 12px 12px;
      height: 72px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      // &:first-child {
      //   margin-top: 30px;
      // }
      &-title {
        margin: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 130%;
        color: #4b5157;
        max-width: 90%;
      }
      &-image {
        margin-right: 24px;
      }
      &_frame {
        margin-left: auto;
        // margin-right: 20px;
        display: flex;
        align-items: center;
        max-width: 500px;
        width: 100%;
        justify-content: space-between;
      }
      &_svg{
        margin-right: 20px;
        margin-left: auto;
      }
      &-teacher{
        margin: 0 15px 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 130%;
        color: #4B5157;
        svg{
          margin-right: 12px;
          margin-left: auto;
        }
      }
      &-progress-folder{
        svg{
          margin-right: 20px;
        }
      }
    }
    &-img {
      background-position: center;
      width: 100%;
    }
  }
  &-homework{
    &-check-label{
      margin: 0 52px 0 0;
      padding: 6px 8px;
      background: #E6F5E7;
      border-radius: 100px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #4B5157;
    }
    &-wait-label{
      margin: 0 26px 0 0;
      width: 104px;
      padding: 6px 8px;
      background: #FFF9E7;
      border-radius: 100px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #4B5157;
    }
    &-label{
      margin: 0 50px 0 0;
      color: #4B5157;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      cursor: pointer;
      &-checked{
        color: #4592FF !important;
      }
    }
    &-no-checkbox{
      margin: 0 15px 0 148px;
    }
    &-input{
      margin: 0 10px 0 0;
      cursor: pointer;
    }
  }
}
