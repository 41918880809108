.footer {
  height: 50px;
  min-height: 50px;
  background-color: pink;
  margin-top: auto;

  .content {
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 20px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}