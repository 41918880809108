.authorization {
    position: relative;
    margin-bottom: 30px;
    margin-top: 10px;
    %background {
        @extend %background-image-cover;
        background-position: initial;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &__login-background {
        @extend %background;
        background-image: url("../../views/Authorization/assets/background.jpg");
    }
    &__signup-background {
        @extend %background;
        background-image: url("../../views/Authorization/assets/background_signup.jpg");
        filter: brightness(0.8);
    }
    &__lesson-state-card {
        margin: auto 126px;
        z-index: 1;
    }
    &_clicable-text{
        text-align: center;
    }
    &-eye{
        position: absolute;
        top: 25%;
        right: 10px;
    }
}