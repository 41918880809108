.lesson-info {
    display: flex;
    justify-content: space-between;
    padding: 32px;
    background: #FFFFFF;
    box-shadow: 0px 50px 200px rgba(214, 198, 171, 0.2);
    border-radius: 20px;
    align-items: center;
    &__wrapper {
        padding: 24px 0;
    }
    &_name {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #30363D;
        width: 50%;
        margin: 0;
    }
    &-teacher {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        &_role {
            margin: 0 0 4px 0;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 14px;
            color: #30363D;
        }
        &_name {
            margin: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #30363D;
        }
    }
}