.my-class {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &__video-frames-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 262px);
    grid-template-rows: repeat(auto-fill, 190px);
    grid-gap: 20px;
  }
  &__bottom-panel-wrapper {
    margin-top: auto;
    position: sticky;
    bottom: 0;
    z-index: 100;
  }
  &_video-frame {
    width: 16.313em;
    height: 11.875em;
    background-color: black;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    position: relative;
  }
  &_title {
    color: white;
  }
  &_student-name {
    color: #4b5157;
    margin: 0;
    // padding: 16px 12px;
    box-sizing: border-box;

    &-individual {
      // height: 56px;
      // padding: 16px 12px;
    }
    &_wrapper {
      height: 56px;
      display: flex;
      align-items: center;
      background-color: #eef2f5;
      border-radius: 16px;
      padding: 0 12px;
    }
  }
  &_video-frame-title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: black;
    padding: 8px 12px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(8px);
    border-radius: 45px;
    margin: 0;
    width: 120px;
    left: 16px;
    top: 144px;
    position: absolute;
  }
  &_button {
    height: 56px;
    width: 261px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid white;
    padding: 13px 12px;
    box-sizing: border-box;
    cursor: pointer;
    &-content {
      display: flex;
      align-items: center;
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 14px;
    }
    &_participant {
      margin: 0 0 0 10px;
      background: #c5e4fc;
      border-radius: 116px;
      padding: 3px 7px;
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 12px;
    }
  }
  &_teacher-button {
    width: 240px;
    justify-content: center;
    .button__icon {
      margin-left: 13px;
    }
  }
  &__control-block {
    height: 30px;
    width: 30px;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 4px rgb(0 0 0 / 14%);
    border-radius: 100px;
    .preloader-wrapper {
      height: 100%;
    }
    .preloaderButton {
      width: 25%;
    }
    &-student-name {
      border-radius: 100px;
      .preloader-wrapper {
        height: 100%;
      }
      .preloaderButton {
        width: 60%;
      }
    }
  }
}

.newContainerWrapper {
  overflow-y: auto;
  flex: 1;
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.myClassWrapper {
  width: 100%;
  display: grid;
  //grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
  grid-template-columns: repeat(auto-fill, 262px);
  //grid-template-rows: repeat(auto-fill, minmax(190px, 1fr));
  grid-template-rows: repeat(auto-fill, 190px);
  grid-gap: 20px;
}

.bottomPanelWrapper {
  margin-top: 20px;
  position: sticky;
  bottom: 32px;
  z-index: 1;
}
