.msa-filter {
  position: relative;
  display: flex;
  align-items: center;
  width: 262px;
  height: 48px;
  padding: 0 16px;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 12px;
  box-sizing: border-box;

  &-icon{
    padding: 0 16px 0 40px;
  }

  &-width{
    width: 100%;
  }
  
  &-tooltip{
    display:none;
  }
  

  &-disabled{
    &:hover{
      border-color: #f4f4f4 !important;
    }
  }

  &_input {
    outline: none;
    width: 100%;
    height: 100%;
    padding: 17px 8px 17px 0;
    background: #f4f4f4;
    border: none;
    border-radius: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #4b5157;

    &-all-selected {
      color: #7b7b7b;
    }

    &-selected {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:hover{
    border-color: #4592ff;
    .msa-filter-tooltip{
      display: block;
      position: absolute;
      top: -2px;
      max-width: 230px;
      background: #4b5157;
      border-radius: 8px;
      color: white;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      padding: 8px;
      cursor: unset;
      z-index: 2;
      box-shadow: 0px 2px 4px 0px #00000024;
      &::after {
        content: '';
        position: absolute;
        left: -16px;
        top: 6px;
        border: 10px solid transparent;
        border-right: 10px solid #4b5157;
      }
    }
  }

  &-errors{
    border-color: #dc3545;
    &:hover{
      border-color: #dc3545 !important;
    }
  }

  &-no-options{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #4B5157;
    margin: 0;
    width: 100%;
    text-align: center;
  }

  &-focused {
    border-color: #4592ff;
  }

  &_button {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 0;
    background: none;
    border: none;
  }

  &_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 8px;
    width: 100%;
    max-height: 200px;
    background: #ffffff;
    border: 2px solid #eef1f5;
    border-radius: 12px;
    overflow-y: auto;
    margin: 0 10px 0 0;

    &-container {
      position: absolute;
      left: 0;
      top: 100%;
      margin-top: 4px;
      width: 100%;
      z-index: 5;
      box-sizing: border-box;
      border-radius: 12px;
      &-top{
        top:-460%;
        &_one-item{
          top:-175%;
        }
        &_two-item{
          top:-285%;
        }
        &_three-item{
          top:-395%;
        }
      }
    }

    &-divider {
      width: 100%;
      height: 0px;
      border: 1px solid #eef1f5;
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 10px 10px 12px;
      gap: 10px;
      width: 100%;
      height: 36px;
      border-radius: 8px;

      &-checked {
        background: #f4f8fc;
      }

      &-checkbox {
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        width: 16px;
        height: 16px;
        border: 1px solid #bdcad2;
        border-radius: 4px;
        cursor: pointer;

        &-checked {
          background: #4592ff;
          border-color: #4592ff;

          &-icon {
            position: absolute;
            left: 14px;
            top: 12px;
          }
        }
      }

      &-label {
        height: 14px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #4b5157;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}


.msa-filter_list::-webkit-scrollbar-track {
  border-radius: 20px;
}

.msa-filter_list::-webkit-scrollbar {
  width: 6px;
}

.msa-filter_list::-webkit-scrollbar-thumb {
  background-color: #4592FF;
  margin: 0 20px 0 0;
  border-radius: 22px;
}