.week-slider {
  width: 262px;
  height: 48px;
  background: #f4f4f4;
  border-radius: 80px;
  padding: 12px 14px;
  display: flex;
  justify-content: space-between;

  &-wrapper {
    display: flex;
    align-items: center;
  }

  &_button {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    background: #e8e8e8;
    border-radius: 90px;
    border: none;
    cursor: pointer;
  }

  &_title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4b5157;
  }

  &_current-button {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #4592ff;
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      color: var(--bs-link-hover-color);
    }
  }
}
