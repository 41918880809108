$panelZIndex: 3;
%side-panel {
  $panelWidth: 261px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: $panelZIndex;
  width: $panelWidth;
  overflow-y: auto;
  & > * {
    max-width: $panelWidth;
  }
}

%top_and_bottom-panel {
  display: flex;
  justify-content: space-between;
  z-index: $panelZIndex;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

%image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

.video-player-all {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 32px 31px 0;
  box-sizing: border-box;
}

.lessonSpace {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.videoBlock {
  width: 100%;
}

.videoBlock2 {
  width: 100%;
  margin-right: 16px;
}

.rightSidebar {
  width: 220px;
  display: flex;
  flex-direction: column;
}

.video-player {
  background: linear-gradient(360deg, rgba(0, 0, 0, 0) 70.26%, rgba(0, 0, 0, 0.5) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 70.18%, rgba(0, 0, 0, 0.5) 100%);
  background-color: #30363d;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 32px 31px 0;
  box-sizing: border-box;
  &:before {
    background-image: url('../../../src/components/VideoPlayer/assets/background.png');
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.1;
  }
  &_main-player {
    @extend .card-container;
  }
  &__video-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 2;
    width: 80%;
  }
  &__video-gradient-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__video-gradient {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 70.26%, rgba(0, 0, 0, 0.2) 100%),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 70.18%, rgba(0, 0, 0, 0.2) 100%);
    }
  }
  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }
  &__video_camera {
    display: unset;
  }
  &__video_screen-share {
    display: unset;
    object-fit: contain;
  }
  &__top-panel {
    @extend %top_and_bottom-panel;
    margin-bottom: 10px;
    z-index: 5;
  }
  &__bottom-panel {
    @extend %top_and_bottom-panel;
    margin-bottom: 32px;
    margin-top: 10px;
    &-button {
      padding: 0.875em 1.125em;
      &-with-icon {
        padding: 0.75em;
      }
    }
  }
  &__middle-section {
    display: flex;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    //z-index: $panelZIndex;
  }
  &__left-panel {
    @extend %side-panel;
    position: relative;
    justify-content: flex-end;
    margin-bottom: 10px;
    &:hover .student-emote_left {
      left: 0;
      transition: 0.5s ease-in-out;
      opacity: 1;
    }
  }
  &__right-panel {
    @extend %side-panel;
    margin-left: 20px;
    overflow-x: hidden;
    &-ring-emoji-chat-wrapper {
      margin-top: auto;
    }
    &:hover .student-emote_right {
      right: 30px;
      transition: 0.5s ease-in-out;
      opacity: 1;
    }
  }
  &__participants-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 2vw;
  }
  &__button-wrapper {
    background-color: #30363d;
    display: flex;
    padding: 8px;
    border-radius: 6.25em;
  }
  &__image-container {
    position: absolute;
    z-index: 3;
    display: flex;
    background-color: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__image-container_hidden {
    display: none;
  }
  &__image {
    @extend %image;
    width: 11.24%;
    height: 14.9%;
    &_person-icon > path {
      fill: $white !important;
    }
    &_camera-icon > path {
      stroke: $white !important;
    }
  }
  &__image_large-size {
    @extend %image;
    width: 30%;
    height: 30%;
  }
  &__top-panel-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &_reverse {
      flex-direction: row-reverse;
    }
  }
  &__top-panel-wrapper_right-content {
    justify-content: flex-end;
  }
}

.video-name {
  font-weight: 800;
  font-size: 36px;
  line-height: 40px;
  color: #4b5157;
  margin-bottom: 48px;
  &_back {
    display: flex;
    background: transparent url('../../assets/img/chevron-left-blue.svg') no-repeat left center;
    padding-left: 16px;
    border: none;
    outline: none;
    color: $blue;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    margin-bottom: 13px;
  }
}
