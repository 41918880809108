.error {
  font-family: Lato;
  font-style: normal;
  font-size: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  &__title {
    width: 381px;
    z-index: 1;
  }

  &__error-number {
    font-weight: 800;
    font-size: 6em;
    line-height: 1.21em;
    color: $gray;
    margin-bottom: 0.17em;
    background-image: url(../../assets/img/errorsStyleElem.svg);
    background-repeat: no-repeat;
    background-position-x: 1.6em;
  }

  &__hint {
    font-weight: 500;
    font-size: 1.75em;
    line-height: 1.21em;
    color: $gray;
    margin-bottom: 1.14em;
  }

  &__button {
    width: 270px;
    font-size: 12px;
  }

  &__image-container {
    position: absolute;
    right: 0;
    display: flex;
    height: 100%;
  }

  &__image {
    max-height: 100%;
    margin: auto 0;
  }

  @media screen and (max-width: 900px),
  screen and (max-height: 438px) {
    &__image-container, &__line-break {
      display: none;
    }

    &__title {
      width: 100%;
    }
  }
}