$blue: #4E88FB;
$gray-85: #4B5157;
.section-recovery {
    padding: 60px 0;
    max-width: 470px;
}

.heading-h1 {
    color: $gray-85;
    font-size: 36px;
    line-height: 40px;
    font-weight: 800;
    margin: 12px 0 48px;
}

label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #262D33;
    margin-bottom: 4px;
}

.link-back {
    color: $blue;
    text-decoration: none;
    font-size: 12px;
    line-height: 14px;
    padding-left: 20px;
    background: url('../../assets/img/chevron.svg') no-repeat left center;
}

.recovery_button {
    margin-top: 32px;
}

.new-password_lable {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    margin-top: 12px;
    background-color: white !important;
    padding-left:unset !important;
    &_error {
        color: #DE373A !important;
    }
}

.recovery-password-success {
    display: flex;
    margin: 0;
    &_email {
        margin: 0;
        color: #4E88FB;
    }
}

.recovery{
    &-state-card{
        margin: auto 126px;
        width: 440px;
        min-height: 200px;
        overflow: unset;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: unset;
    }
    &-second-state-card{
        margin: auto 126px;
        width: 440px;
        min-height: 200px;
        overflow: unset;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: unset;
        .lesson-state-card__hint{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #E9EFF3;
        }
        .recovery-email-hint{
            font-style: normal;
            font-weight: 700 !important;
            font-size: 12px;
            line-height: 16px;
            color: #E9EFF3;
        }
    }
    &-background{
        @extend %background-image-cover;
        background-position: initial;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../components/Recovery/assets/recovery-background.jpg");
    }
    &-submit{
        background: #217DFF;
        border-radius: 16px;
        padding: 16px 14px 16px 20px;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        width: 100%;
        height: 48px;
        border: unset;
        margin-top: 30px;
        svg{
            stroke: white;
        }
    }
    &-form{
        width: 100%;
    }
}