.home {
    &__container{
        box-shadow: 0px 8px 12px rgba(214, 211, 206, 0.16);
        min-height: 670px;
    }
    &__join-btn{
        background: #4592FF;
        border-radius: 8px;
        height: 48px;
        width: 222px;
        justify-content:center;
        &:hover{
            background: #217DFF;
        }
    }
    &__background {
        @extend %background-image-cover;
        background-position: initial;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../views/Home/assets/home-page-bg.jpg");
    }
    &__lesson-state-card {
        margin: auto 126px;
        width: 532px;
        min-height: 200px;
        overflow: unset;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: unset;
        border-radius: 20px;
        .lesson-state-card__header-container{
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px dashed rgba(255, 255, 255, 0.24);
        }
        .lesson-state-card__title{
            font-style: normal;
            font-weight: 900;
            font-size: 42px;
            line-height: 50px;
            color: #FFFFFF;
        }
        .lesson-state-card__hint{
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 120%;
            color: #FFFFFF;
        }
    }
    &_motivation{
        &-wrapper{
            display: flex;
            align-items: center;
            width: 100%;
        }
        &-text{
            margin: 0 0 0 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            color: #FFFFFF;
        }
    }

    &_info{
        &-wrapper{
            background: #FFFFFF;
            border-radius: 12px;
            padding: 20px;
            min-height: 100px;
            display: flex;
            text-align: start;
            border: 1px solid transparent;
            align-items: center;
            margin-top: 24px;
            box-shadow: 0px 8px 12px rgba(214, 211, 206, 0.16);
        }
        &-title{
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #4B5157;
            margin-bottom: 6px;
        }
        &-text{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            margin: 0;
            color: #4B5157;
        }
        &-link{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 130%;
            margin: 0;
            color: #4592FF;
            text-decoration: none;
        }
    }
   
}
