.alert{
    &-button{
        border: unset;
        background: transparent;
        position: relative;
        &-active{
            content: "";
            position: absolute;
            height: 5px;
            width: 5px;
            border-radius: 100%;
            background-color: red;
            right: 8px;
            top: 15px;
        }
    }
    &-title{
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #4B5157;
    }
    &-item{
        background: #F4F8FC;
        border-radius: 12px;
        padding: 20px;
        border: 1px solid #F4F8FC;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        &-not-readed{
            border: 1px solid #FFDB75;
        }
        &:last-child{
            margin-bottom: 0;
        }
        
        &-title{
            margin: 0 0 6px 0;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 130%;
            color: #4B5157;
        }
        &-discription{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #7B7B7B;
            margin: 0;
        }
        &-time{
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            color: #7B7B7B;
            margin: 0;
        }
        &-meta{
            &:hover{
                background: #EEF1F5;
              }
        }
    }
}