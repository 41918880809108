.video-frame {
    width: 261px;
    height: 147px;
    max-width: 100%;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    padding: 10px;
    box-sizing: border-box;
    &__container {
        z-index: 1;
        border-radius: 20px;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: black;
        overflow: hidden;
        box-sizing: border-box;
        &_hidden {
            background-color: #30363D;
        }
        &_clickable {
            cursor: pointer;
            transition: .2s;
            &:hover {
                opacity: .7;
            }
        }
        &_teacher-media {
            border: 4px solid #6840a4;
        }
        &_local-media {
            border: 4px solid #65b2a2;
        }
    }
    &_hidden {
        height: unset;
    }
    path {
        stroke: black;
    }
    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: none;
        z-index: 4;
    }
    &__video_camera {
        display: unset;
        &_local-video {
            transform: scaleX(-1);
        }
    }
    &__video_screen-share {
        object-fit: contain;
        display: unset;
    }
    &__video_hidden {
        display: none;
    }
    &__control-block-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        &_button {
            padding: 7px;
            height: 30px;
            width: 30px;
        }
        &_text {
            margin: 0;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
            background-color: #EEF2F5;
            border-radius: 45px;
            color: #30363D;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            min-width: 14px;
            text-align: center;
        }
    }
    &_my-frame {
        padding: 6px 13px;
        background-color: #EEF2F5;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
        //backdrop-filter: blur(8px);
        border-radius: 100px;
        margin: 0;
        &_my-camera {
            color: white;
            margin: 0 0 0 12px;
        }
        &_on-camera {
            path {
                stroke: white;
            }
            background-color: transparent;
        }
    }
    &_close-frame {
        display: flex;
        justify-content: space-between;
        background: #4B5157;
        border-radius: 16px;
        padding: 16px 12px;
        cursor: pointer;
    }
    &_text {
        margin: 0;
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        color: #FFFFFF;
        align-self: center;
        font-style: normal;
        font-weight: bold;
    }
    &_all-class-mode {
        width: 262px;
        height: 190px;
    }
}