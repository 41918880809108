.timesheet {
  &-user {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #4b5157;
    margin-bottom: 30px;
  }
  &-create-event {
    &-start-at {
      &:hover {
        border-color: #4e88fb;
      }
      &-invalid {
        border: 1px solid #dc3545 !important;
        &:hover {
          border-color: #dc3545;
        }
      }
      &:disabled {
        border: 1px solid #f4f4f4;
        background-color: #f4f4f4;
        &:hover {
          border-color: #f4f4f4;
        }
      }
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
    }
  }

  &-banner {
    &-no-event {
      padding: 24px 20px;
      background: #fff9e7;
      border-radius: 12px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }
    &-off-event {
      padding: 24px 20px;
      background: #f7f3fc;
      border-radius: 12px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }
    &-img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 130%;
      color: #4b5157;
      margin: 0 0 6px 0;
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #7b7b7b;
      margin: 0;
    }
  }

  &_button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 222px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    padding: 16px 14px 16px 20px;
    border: 2px solid #4592ff;
    border-radius: 16px;
    height: 48px;

    &-create {
      color: white;
      background-color: #4592ff;

      svg {
        fill: white;
      }

      &:hover {
        background-color: #217dff;
        border: 2px solid #217dff;
        color: white;
        svg {
          fill: white;
        }
      }
    }

    &-download {
      color: #4592ff;
      background-color: white;

      svg {
        fill: #4592ff;
      }

      &:hover {
        border: 2px solid #4b5157;
        color: #4b5157;
        svg {
          fill: #4b5157;
        }
      }
    }

    &-container {
      display: flex;
    }
  }

  &_filter {
    width: 262px;
  }

  &-table {
    border-collapse: separate;
    border-spacing: 4px;
    table-layout: fixed;
    width: 100%;

    &_clock {
      display: flex;
      justify-content: center;
    }

    td {
      width: 44px;
      border: 1px solid #e9eff3;
      border-radius: 4px;
      padding: 0;
    }

    td + td {
      width: auto;
    }

    thead tr td {
      height: 45px;
      background-color: #f4f8fc;
    }

    tbody tr td {
      height: 200px;
      border: 1.5px solid #f4f8fc;
    }

    tbody tr td:first-child {
      background-color: #f4f8fc;
    }

    &_date-cell {
      padding-left: 24px;
    }

    &_date {
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 120%;
      color: #7b909f;
      margin-bottom: 4px;
    }

    &_week-day {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #5b6168;
      text-transform: uppercase;
    }

    &_time-cell {
      display: flex;
      justify-content: center;
      height: 100%;
      padding-top: 20px;
    }

    &_time {
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 120%;
      color: #4b5157;
    }

    &_item-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  &_actions-wrapper {
    display: flex;
    height: 48px;
    margin-bottom: 30px;
  }

  &_item {
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    width: calc(100% + 3px);
    background-color: #ff00ff10;
    border-radius: 4px;
    background-color: #ff00ff15;
    z-index: 2;
  }

  &_event {
    &-create_btn {
      background: #4592ff;
      border-radius: 16px;
      align-items: center;
      padding: 16px 20px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      border: unset;
      width: 100%;
    }
  }
  &-change-status {
    width: 262px;
  }
}

.lesson-card {
  padding: 6.5px;
  width: 100%;
  height: 100%;
  border: 1.5px dashed transparent;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  color: #4b5157;

  &_main-content {
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &_title {
    font-style: normal;
    max-height: 28px;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    margin-bottom: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 85%;
  }

  &_subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    color: #a5a8ab;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &_student {
    height: 24px;
    overflow: hidden;
    position: absolute;
    top: 1px;
    right: 0;

    &-name {
      font-size: 6px;
      margin-top: 4px;
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
    }
  }

  &_description {
    margin-top: 15px;
    padding: 0;

    &-text {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &_time {
    position: absolute;
    bottom: 8px;
    left: 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 140%;
    color: #4b5157;
  }
}

.notification-card {
  display: flex;
  align-items: flex-start;
  padding: 8px;
  width: 100%;
  height: 100%;
  background: #ffdb75;
  border-radius: 4px;

  &_bell {
    width: 13px;
    height: 14px;
    margin-right: 4px;
  }

  &_message {
    max-width: 100%;
    height: 100%px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #4b5157;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}
