.tooltip-inner {
  max-width: 200px;
  padding: 6px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #4b5157;
  text-align: center;
  background: #f4f4f4;
  border-radius: 14px;
}

.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
  border-right-color: #f4f4f4;
}

.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before {
  border-left-color: #f4f4f4;
}
