.button {
  font-size: inherit;
  font-family: inherit;
  text-align: center;
  align-items: center;
  border: none;
  padding: 1.42em 1.67em;
  border-radius: 1.33em;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  color: $white;
  &_justify-content-center {
    justify-content: center;
  }
  &_justify-content-between {
    justify-content: space-between;
  }
  &:disabled {
    opacity: 0.5;
  }
  &__icon {
    display: flex;
    margin-left: 0.83em;
  }
  &__icon-back {
    width: 96px;
    height: 96px;
  }
  &_primary {
    background-color: $blue;
    .button__icon {
      fill: white;
      stroke: white;
    }
    &_disabled {
      background-color: #c4c4c4;
      color: #aaaaaa;
      cursor: not-allowed;
      .button__icon svg {
        fill: #aaaaaa;
        stroke: #aaaaaa;
      }
    }
  }
  &_secondary {
    border: 1px solid $white;
    background-color: transparent;
    &_disabled {
      background-color: #c4c4c4;
      color: #aaaaaa;
      cursor: not-allowed;
    }
  }
  &_navigation {
    padding: 0;
    border: 1px solid transparent;
    background-color: transparent;
    color: #4e88fb;
  }
  &_white {
    color: black;
    background-color: white;
  }
  &_red {
    color: $white;
    background-color: $red;
  }
  &_green {
    color: $white;
    background-color: $green;
  }
  &_video-framed {
    background-color: #eef2f5;
  }
  &_rounded {
    padding: 0.875em;
    border-radius: 6.25em;
  }
  &_circle {
    .button__icon {
      margin-left: 0;
      .icon-download path {
        stroke: #4b5157 !important;
      }
    }
    padding: 0.875em;
    border-radius: 6.25em;
  }
  &_video-frame {
    .button__icon {
      margin-left: 0;
      .icon-download path {
        stroke: #4b5157 !important;
      }
    }
    padding: 0.4em;
    border-radius: 6.25em;
  }
}

.btn-back {
  background: transparent url('../../assets/img/chevron-left-blue.svg') no-repeat left center;
  padding-left: 16px;
  border: none;
  outline: none;
  color: $blue;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
}

.btn-border {
  padding: 12px 12px !important;
  border-start-end-radius: 12px !important;
  border-end-end-radius: 12px !important;
  height: 48px;
  border-color: #939699;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:hover {
    border-color: $blue !important;
  }
}
