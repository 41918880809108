.btn {
    padding: 15px 32px;
    border-radius: 16px;
    font-size: 14px;
    //border: none;
    //background-color: ;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
    outline: 0 none;
}

//
.btn-primary {
    background: $blue;
    border: none;
}

.btn-outline-primary {
    border: 1px solid $blue;
    color: $blue;
    background-color: white;
    svg {
        fill: #4592FF;
    }
    &:hover {
        color: white;
    }
    &:hover svg {
        fill: white;
    }
    &:focus {
        background-color: white;
        color: $blue;
    }
}

// Заменить
.btn-back {}

//
//.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
//  background: #33a6cc;
//}
//
//.btn-primary:active, .btn-primary.active {
//  background: #007299;
//  box-shadow: none;
//}