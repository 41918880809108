.lesson-end {
    position: relative;
    &__background {
        @extend %background-image-cover;
        background-position: initial;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../../views/LessonEnd/assets/background.jpg");
        filter: brightness(0.8);
    }
    &__lesson-state-card {
        margin: auto 126px;
        width: 355px;
        // height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}