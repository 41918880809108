$--bs-font-sans-serif: Lato;
#root {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

html {
  @extend %discard-margin-and-padding;
  font-family: Lato, sans-serif;
}

body {
  @extend %discard-margin-and-padding;
  background-image: url('../assets/background.svg');
  background-color: #fdf8ef;
  font-family: Lato, sans-serif;
}

html,
body {
  font-size: 16px;
}

//iframe {
//  display: none;
//}
