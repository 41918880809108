.student-emote {
  padding: 12px;
  background: #ffffff;
  border-radius: 16px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  &_left {
    left: -175px;
    width: 181px;
    box-sizing: border-box;
    transition: 0.5s ease-in-out;
  }
  &_right {
    box-sizing: border-box;
    right: 30px;
    bottom: 116px;
  }
  &_mobile {
    left: 0;
    right: 0;
  }
  &-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 70px;
    width: 100%;
    border-radius: 16px;
    cursor: pointer;
    box-sizing: border-box;
    &_toilet {
      background: #ffd150;
      margin-bottom: 12px;
      .button__icon {
        margin-left: 0;
      }
    }
    &_hand-up {
      background: #81e3cf;
      .button__icon {
        margin-left: 0;
      }
    }
    &_firstThemeButton {
      background: white;
      border: 1px;
      padding: 2px;
      margin-bottom: 12px;
      box-sizing: border-box;
      .button__icon {
        margin: 0;
      }
    }
    &_secondThemeButton {
      background: white;
      padding: 2px;
      border: 1px;
      box-sizing: border-box;
      .button__icon {
        margin: 0;
      }
    }
    &-image {
      border-radius: 10px;
      width: 200px;
      height: 200px;
    }
  }
}

.student-emote.student-emote_right.student-emote_mobile {
  left: unset;
}
