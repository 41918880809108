.delete-modal {
  &__btn-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__confirm-button {
    background-color: white;
    border: 1px solid #4E88FB;
    box-sizing: border-box;
    border-radius: 100px;
    color: #4E88FB;
    width: 270px;
    display: block;
    padding: 14px 20px;
    height: 48px;
    margin-right: 24px;
  }

  &__cancel-button {
    margin-right: 20px;
    background: #4E88FB;
    width: 270px;
    display: block;
    padding: 14px 20px;
    height: 48px;
    border-radius: 100px;
    box-sizing: border-box;
  }
  &_info-text{
    margin: 10px 0 0 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #272833;
  }
  &__new-submit{
    background: #4592FF;
    border-radius: 16px;
    align-items: center;
    padding: 16px 20px;
    width: 222px;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    &:hover{
      background: #217DFF;
    }
  }
}
