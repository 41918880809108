.dropdown {
    font-size: 1rem;
    font-weight: normal;
    position: relative;
    display: flex;
    &_student-selected {
        background-color: transparent;
    }
    &_button {
        width: 1.875em;
        height: 1.875em;
    }
    &_icon {
        width: 1em;
        height: 1em;
    }
    &__button {
        font-size: 1em;
        width: 1.875em;
        height: 1.875em;
        border-radius: 100px;
        background-color: #EEF2F5;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
        cursor: pointer;
        border: none;
        padding: 0;
    }
    &__button_icon-type {
        @extend %reset-button;
        background-color: unset;
        box-shadow: unset;
        opacity: 1;
        cursor: pointer;
        width: 1em;
        height: 1em;
    }
    &__button-icon-container {
        width: 1em;
        height: 1em;
        margin: auto;
        display: flex;
    }
    &__button-icon {
        margin: auto;
        svg {
            width: 100%;
            height: 100%;
            path {
                stroke: #30363D;
            }
        }
    }
    &__button-icon-close {
        width: 43.75%!important;
        height: 43.75%!important;
    }
    &__list {
        background-color: #EEF2F5;
        opacity: 0;
        border-radius: 0.875em;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
        overflow: auto;
        position: absolute;
        top: calc(100% + 25px);
        &_visible {
            opacity: 1;
        }
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        &_left-position {
            right: 0;
        }
        &_center-position {
            left: 50%;
            transform: translateX(-50%);
        }
        &_right-position {
            left: 0;
        }
    }
    &__item {
        font-size: 0.6875em;
        padding: 1.18em 1.09em; //13px 12px;
        display: flex;
        align-items: center;
        transition: .3s;
        box-sizing: border-box;
        min-width: 17.3em; //190px;
        color: $gray;
        &-icon {
            font-size: 1.45em;
            min-width: 1em; //16px
            min-height: 1em; //16px
            width: 1em; //16px
            // height: 1em; //16px
            margin-right: .5em;
            svg {
                width: 100%;
                height: 100%;
                path {
                    stroke: $gray;
                }
            }
        }
        &-text {
            white-space: nowrap;
        }
        &-active-element {
            margin-left: auto;
            padding-left: .73em;
            display: flex;
        }
        &_clickable {
            cursor: pointer;
            &:hover {
                background-color: #0000001f;
            }
        }
    }
}